body {
  background-color: #222222 !important;
  color: #cecece !important;
  font-family: "Poppins", sans-serif !important;
}

.profile-intro-block {
  text-align: center;
  margin: 60px auto !important;
  background-image: url(./deegayu-profile-image.jpg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.title {
  width: 40%;
  margin: 40px auto;
}

.title img {
  width: 100%;
}

.title-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 80px;
}

.sub-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 60px;
  letter-spacing: 5px;
}

.intro {
  text-align: justify;
}

.scroll-animation {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: none !important;
}

.portfolio {
  margin-top: 100px !important;
  border-top: 1px solid #333333;
  padding-top: 80px;
}

.portfolio-item {
  margin-bottom: 80px;
}

.port-title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.port-sub-title {
  font-size: 12px;
  color: #a4a4a4;
}

.port-desc {
  font-size: 14px;
}

.portfolio-images {
  text-align: center;
}

.portfolio-images img {
  width: 100%;
}

.bottom-desc {
  margin: 100px 0 !important;
  text-align: justify;
}

.mouse {
  margin: auto;
  width: 30px;
  height: 60px;
  border: 2px solid #484848;
  border-radius: 60px;
  position: relative;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #505050;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .profile-intro-block {
    background-image: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .profile-intro-block {
    background-image: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .profile-intro-block {
    background-image: url(./deegayu-profile-image.jpg);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
